import React from "react"

import grid from '../styles/grid.module.scss'
import logo from '../../static/venturi.svg'
import ok from '../../static/messageok.png'
import btn from '../../static/btn_form.png'
import linkedin from '../../static/icon_linkedin.svg'
import whatsapp from '../../static/icon_whatsapp.svg'
import facebook from '../../static/icon_facebook.svg'
import instagram from '../../static/icon_instagram.svg'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      cellPhone: '',
      email: '',
      message: '',
      showingSucess: false,
      showMap: false
    };
  };

  async componentDidMount() {
    setTimeout(() => {
      this.setState({ showMap: true });
    }, 2500);
  }

  // openChat = () => {
  //   setTimeout(() => {
  //     if (!window.drift) this.openChat();
  //     else window.drift.api.openChat();
  //   }, 2500);
  // }

  onReset = async () => {
    this.setState({
      showingSucess: true,
      name: '',
      cellPhone: '',
      email: '',
      message: '',
    });

    setTimeout(() => this.setState({ showingSucess: false }), 2500)
  }

  handleChange = (prop, event) => this.setState({ [prop]: event.target.value })

  onSubmit = () => {
    const { name, email } = this.state;

    window['ga']('send', 'pageview', '/contacted');
    window['ga']('send', 'pageview', email + ';' + name);

    if (name.length < 3 && email.length < 5) {
      alert('Não recebemos sua mensagem =(.\nPor favor, nos envie um email: me@vntrx.com')
      return;
    }

    const body = { ...this.state };

    fetch('//vntrx-service.azurewebsites.net/mail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then(({ statusCode }) => {
        if (statusCode === 200) this.onReset()
        else alert('Não recebemos sua mensagem =(.\nPor favor, nos envie um email: me@vntrx.com')
      })
      .catch((err) => this.setState({ show: true }));
  };


  render() {
    const { showMap } = this.state;
    return (
      <footer name="contato">
        <div className={grid.cnt_center}>
          <div className="cnt-data">
            <div className="header_logo">
              <img src={logo} alt="Venturi Soluções" title="Venturi Soluções" />
            </div>
            <h3>
              ENTRE EM CONTATO E CONHEÇA NOSSOS CASES
            </h3>
            <div className="header_contact">
              <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5513981213220" rel="noopener noreferrer" target="_blank">
                <img src={whatsapp} alt="WhatsApp" />
              </a>

              <a href="https://www.instagram.com/vntrxs/" rel="noopener noreferrer" target="_blank">
                <img src={instagram} alt="instagram" />
              </a>

              <a href="https://www.facebook.com/Venturi-X-Solu%C3%A7%C3%B5es-em-TI-623373261454770/" rel="noopener noreferrer" target="_blank">
                <img src={facebook} alt="facebook" />
              </a>

              <a href="https://www.linkedin.com/company/vntrx" rel="noopener noreferrer" target="_blank">
                <img src={linkedin} alt="LinkedIn" />
              </a>
            </div>
            <div className="header_contact">
              <h5>
                <a href="tel:11338846683">+55 11 <b>3884-6693</b> <br /></a>
                <a href="mailto:me@vntrx.com">me@vntrx.com</a>
              </h5>
            </div>
            <div className="header_address">
              Av. Paulista, 967 - Sexto Andar <br />
              Bela Vista, São Paulo - SP, 01311-100
            </div>
            {showMap && <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29255.939091694396!2d-46.6429694046936!3d-23.568716923721407!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3637fb2d4959fae6!2sVenturi+X+Solu%C3%A7%C3%B5es+em+TI!5e0!3m2!1sen!2smy!4v1557620477031!5m2!1sen!2smy" width="100%" height="250" frameborder="0" allowfullscreen></iframe>}
          </div>
          <div className={`cnt-form ${this.state.showingSucess ? 'hidden' : 'shown'}`}>
            <h1>
              ENTRE EM CONTATO
            </h1>
            <div className="form">
              <input className="input-100" value={this.state.name} onChange={event => this.handleChange('name', event)} name="nome" type="text" placeholder="Nome" required />

              <input placeholder="Telefone com DDD" name="(11) 99999-9999" value={this.state.cellPhone} onChange={event => this.handleChange('cellPhone', event)} />

              <input className="input-50" value={this.state.email} onChange={event => this.handleChange('email', event)} name="email" type="email" placeholder="Email" required />

              <textarea className="input-100" value={this.state.message} onChange={event => this.handleChange('message', event)} name="message" placeholder="Mensagem" ></textarea>

              <div className="send">
                <input id="check" type="checkbox" />
                <label>
                  desejo receber informações sobre a Venturi X
              </label>
                <button onClick={this.onSubmit}>
                  <img src={btn} alt="Enviar" />
                </button>
              </div>
            </div>
          </div>

          {this.state.showingSucess && <div className='successSent shown'>
            <img src={ok} alt="Mensagem enviada" title="ok" />
            <h1>MENSAGEM ENVIADA COM SUCESSO!</h1>
          </div>}
        </div>
      </footer>


    )
  }
}
export default Footer
