import React from "react"
import Helmet from 'react-helmet'
import Slider from "react-slick";


import Menu from "../components/navmenu";

import global from '../styles/grid.module.scss'
import header from '../styles/header.module.scss'
import logo from '../../static/venturi.svg';
import icon from '../../static/favicon.png';

import appfirst from '../../static/001.png';
import appsecond from '../../static/002.png';
import appthird from '../../static/003.png';

import ogImage from '../../static/v2019-min.jpg';

import basemobile from '../../static/mobile.png';

import mobile_float from '../../static/float.png';

class Header extends React.Component {

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 1500,
      fade: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false
    };
    return (

      <header className={header.cnt_header_home}>
        <Helmet>
          <title>Venturi X Soluções em TI - Desenvolvimento de Soluções</title>
          <meta name="description" content="Venturi X Soluções em TI - Desenvolvimento de Soluções" />
          <meta name="keywords" content="HTML,Desenvolvimento,JavaScript,Front-End, Back-End, Soluções Digitais, Web development, Desenvolvimento de Aplicativos, Consultoria, Software development, Solucões Digitais" />
          <meta name="author" content="Venturi X Soluções em TI"></meta>

          <meta http-equiv="Content-Language" content="pt-BR, en-US" />

          <meta property="og:site_name" content="Venturi X Soluções em TI" />
          <meta property="og:title" content="VNTRX – Desenvolvimento de aplicativos, sistemas, integrações, nuvem e muito mais" />
          <meta property="og:url" content="https://vntrx.com" />
          <meta property="og:description" content="Desenvolvimento de aplicativos mobile, web e api. Times de alta performance evoluindo produtos e pessoas, trazendo valor agregado e diferencial aos produtos de nossos clientes" />
          <meta property="og:image" content="//imagevntrx.png" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="pt_BR" />

          <link rel="icon" type="image/x-icon" href={icon} />

          <meta name="theme-color" content="#aa59f1" />
          
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css" />
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css" />
        </Helmet>
        <div className={global.cnt_center}>
          <div className={global.cnt_flex_between}>
            <div className={header.logo} >
              <img alt='Venturi X' src={logo} />
            </div>
            <div className={header.cnt_nav_menu}>
              <Menu />
            </div>
          </div>

          <div className={header.cnt_header_info}>
            <div className={header.text_link}>
              <h1>Somos <span> apaixonados</span> por <span>soluções</span> que <span>transformam</span> pessoas e negócios</h1>
              <div className={header.link}><a href="#solucoes">Saiba Mais</a></div>
            </div>
            <div className={header.mobile_img}>
              <img className={header.float_img} src={mobile_float} alt="Mobile" title="Mobile" />
              <div className={header.cnt_slide} >
                <Slider {...settings}>
                  <div>
                    <img className={header.mobile} src={appfirst} alt="Mobile" title="Mobile" />
                  </div>
                  <div>
                    <img className={header.mobile} src={appsecond} alt="Mobile" title="Mobile" />
                  </div>
                  <div>
                    <img className={header.mobile} src={appthird} alt="Mobile" title="Mobile" />
                  </div>

                </Slider>
                <div className={header.base_mobile}>
                  <img src={basemobile} alt="Mobile" title="Mobile" />
                </div>


              </div>
            </div>



          </div>
        </div>

      </header>


    )
  }

}
export default Header 
