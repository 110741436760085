import React from "react"
import Helmet from 'react-helmet'
import Header from "../components/header"
import Footer from "../components/footer"
import grid from '../styles/grid.module.scss'
import workflow from '../../static/vntrx-workflow.svg'
import organogram from '../../static/organogram.png'
import separator from '../../static/second.png'
import SliderThumb from '../components/SliderThumb'
import ivc from '../../static/customers/ivc.jpg'
import appia from '../../static/customers/appia.jpg'
import lanup from '../../static/customers/lanup.jpg'
import tranpo from '../../static/customers/tranpo.jpg'
import cabify from '../../static/customers/cabify.jpg'
import birdymee from '../../static/customers/birdymee.jpg'
import abasteci from '../../static/customers/abasteci.jpg'
import efficacy from '../../static/customers/efficacy.jpg'
import givens from '../../static/customers/givens.jpg'
import indicatudo from '../../static/customers/indicatudo.jpg'
import medlink from '../../static/customers/medlink.jpg'
import sptrans from '../../static/customers/sptrans.jpg'
import vindimo from '../../static/customers/vindimo.jpg'
import upgas from '../../static/customers/upgas.jpg'
import zonaazul from '../../static/customers/zonaazul.jpg'

const data = [{
  label: 'dd-chan',
  image: 'http://kenwheeler.github.io/slick/img/lazyfonz2.png',
  message: 'O melhor aplicativo já feito pra minha empresa'
}, {
  label: 'dd-chan',
  image: 'http://kenwheeler.github.io/slick/img/lazyfonz2.png',
  message: 'Melhor experiencia da minha vida, empresa top'
}, {
  label: 'dd-chan',
  image: 'http://kenwheeler.github.io/slick/img/lazyfonz2.png',
  message: 'Equipe muito divertida e profissional'
}, {
  label: 'dd-chan',
  image: 'http://kenwheeler.github.io/slick/img/lazyfonz2.png',
  message: 'Melhor experiencia da minha vida, empresa top'
}]


const dataLogo = [{
  label: 'IVC Brasil',
  image: ivc,
}, {
  label: 'Tranpo',
  image: tranpo,
}, {
  label: 'Birdymee',
  image: birdymee,
}, {
  label: 'Efficacy',
  image: efficacy,
}, {
  label: 'Abasteci',
  image: abasteci,
}, {
  label: 'Lanup',
  image: lanup,
}, {
  label: 'Appia',
  image: appia,
}, {
  label: 'Cabify',
  image: cabify,
}, {
  label: 'Givens',
  image: givens,
}, {
  label: 'IndicaTudo',
  image: indicatudo,
}, {
  label: 'Medlink',
  image: medlink,
}, {
  label: 'SpTrans',
  image: sptrans,
}, {
  label: 'Vindimo',
  image: vindimo,
}, {
  label: 'UpGas',
  image: upgas,
}, {
  label: 'ZonaAzul',
  image: zonaazul,
}]

export default () =>
  <div>
    <Helmet>
      <title>Venturi</title>
      <meta name="theme-color" content="#FF0000" />
    </Helmet>
    <Header />

    <section name="solucoes" className={grid.cnt_center} title="Atêlie de soluções">
      <h1 className="section_title">Soluções <span>inovadoras</span></h1>
      <h6 className="paragraph-solutions section_paragraph">
        <br />
        Desenvolvimento de aplicativos móveis, software sob medida, UX Design, integração, nuvem, devops e muito mais.
        <br />
        Somamos cultura, boas práticas e time de alta performance em um modelo eficaz.
        <br />
        <br />
        Venturi Conception:
      </h6>
    </section>
    <section name="workflow" title="Co-criação">
      <img className="img-solutions" src={workflow} alt="workflow" title="Co-criação" />
    </section>
    {/* <section name="cases" className={grid.cnt_black} title="Cases">
      <SliderThumb data={data} isRadius={true}/>
    </section> */}
    <section name="venturi" className={grid.section_us} title="Software sob medida">
      <div className="sep">
        <img src={separator} alt="sp" title="sp" />
      </div>
      <div className={grid.cnt_center}>
        <div className={grid.cnt_flex}>
          <div className="is-45">
            <div title="Times de alta performance">
              <h1 className="section_title white whoweare_title">A <span>Venturi X</span> </h1>
              <p className="section_paragraph white whoweare_paragraph">
                A Venturi X Soluções é um ateliê de soluções, processos e desenvolvimento de software focada em conceitos e metodologias ágeis, que tem como principal estratégia a idealização do produto junto ao cliente, buscando o máximo de retorno sobre investimento.
              </p>
            </div>

            <div name="value">
              <h1 className="section_title white whoweare_title">Solução <span>é aqui</span></h1>
              <p className="section_paragraph white whoweare_paragraph">
                Pessoas apaixonadas por inovação e tecnologia, realizam grandes projetos e colocam em prática as
                melhores ideias, seja com uma solução de software, devops, mobile ou nuvem.
                <br /> <br />
                A Venturi está de coração aberto para inovar e iniciar a transformação digital
                junto com você, acreditamos na sua idéia e com expertise queremos ajudar você a realiza-la hoje!
              </p>
            </div>
          </div>
          <div className="img-us">
            <img src={organogram} alt="team" title="team" />
          </div>
        </div>

        {/* <div name="team" style={{ 'display': 'none' }}>
          <ul className="list-team">
            <li>
              <div className="img-team">
                <img src={people} alt="profile" title="ceo" />
              </div>
              <h1>Elton Ventura</h1>
              <p> CEO & Fundador </p>
            </li>
            <li>
              <div className="img-team">
                <img src={people} alt="profile" title="ceo" />
              </div>
              <h1>Elton Ventura</h1>
              <p> CEO & Fundador </p>
            </li>
            <li>
              <div className="img-team">
                <img src={people} alt="profile" title="ceo" />
              </div>
              <h1>Elton Ventura</h1>
              <p> CEO & Fundador </p>
            </li>
          </ul>
        </div> */}
      </div>
    </section>
    <section name="brief" className={grid.cnt_black} title="Brief">
      <SliderThumb data={dataLogo} slidesToShow={5} dots={false} />
    </section>
    <hr style={{ borderColor: '#181818' }} />
    <Footer />

  </div>


