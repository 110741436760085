import Slider from 'react-slick'
import React from 'react'

const brief = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

const img = {
  borderRadius: 200,
  border: 'solid 2px'
}

const settings = {
  centerMode: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 3500,
  autoplaySpeed: 3500,
};

const Mark = ({ datax: { label, image, message }, isRadius }) => <div style={brief}>
  <img style={{ width: 80, ...(isRadius ? img : { opacity: .3 }) }} src={image} alt={label} />
  <span style={(!isRadius ? { opacity: .3 } : {})}>{label}</span>
  <span>{message}</span>
</div>
const SliderThumb = ({ data, isRadius, slidesToShow = 2, dots = true }) => <Slider {...{ ...settings, slidesToShow, dots }}>
  {data.map((item, key) => <Mark key={key} datax={item} isRadius={isRadius} />)}
</Slider>

export default SliderThumb
