import React from "react"
import $ from "jquery"

import menu from '../styles/navmenu.module.scss'


// document.addEventListener('click', function (event) {

// 	// If the clicked element doesn't have the right selector, bail
// 	if (!event.target.matches('.abrir')) return;

// 	// Don't follow the link
// 	event.preventDefault();

// 	// Log the clicked element in the console
// 	console.log(event.target);

// }, false);








class Menu extends React.Component {
  componentDidMount(){
    if (typeof window !== `undefined`) {
      $('.menu_icon').on('click', function(){
        $('nav ul').toggleClass('opened');
        $(this).toggleClass('open');  
      })
    } 
  }
 

  render() {
    return (
      <nav>
        <ul className={menu.nav_menu}>
          <li><a href="#solucoes">Soluções</a></li>
          <li><a href="#venturi">A Venturi X</a></li>
          <li><a href="#contato">Contato</a></li>
          {/* <li><a href="#team">Equipe</a></li> */}
        </ul>
      </nav>

    )
      }

}
export default Menu

